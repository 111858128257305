/* eslint-disable react/display-name */
import { ServiceTypes } from '~/types';

import AudiomackIcon from '~/app/components/Icon/AudiomackIcon';
import BeatportIcon from '~/app/components/Icon/BeatportIcon';
import TraxsourceIcon from '~/app/components/Icon/TraxsourceIcon';
import { Icon } from '~/app/components/Icon/toIcon';
import BandcampIcon from '~/app/components/Icon/BandcampIcon';
import DeezerIcon from '~/app/components/Icon/DeezerIcon';
import AmazonIcon from '~/app/components/Icon/AmazonIcon';
import JioSaavnIcon from '~/app/components/Icon/JioSaavnIcon';
import KkBoxIcon from '~/app/components/Icon/KkBoxIcon';
import NapsterIcon from '~/app/components/Icon/NapsterIcon';
import PandoraIcon from '~/app/components/Icon/PandoraIcon';
import TidalIcon from '~/app/components/Icon/TidalIcon';
import YouTubeIcon from '~/app/components/Icon/YouTubeIcon';
import YouTubeMusicIcon from '~/app/components/Icon/YouTubeMusicIcon';
import SoundCloudIcon from '~/app/components/Icon/SoundCloudIcon';
import YandexIcon from '~/app/components/Icon/YandexIcon';
import GaanaIcon from '~/app/components/Icon/GaanaIcon';
import AppleIcon from '~/app/components/Icon/AppleIcon';
import SpotifyIcon from '~/app/components/Icon/SpotifyIcon';
import AudiusIcon from '~/app/components/Icon/AudiusIcon';
import TwitterIcon from '~/app/components/Icon/TwitterIcon';
import FacebookIcon from '~/app/components/Icon/FacebookIcon';
import GoogleIcon from '~/app/components/Icon/GoogleIcon';
import TikTokIcon from '~/app/components/Icon/TikTokIcon';
import OtotoyIcon from '~/app/components/Icon/OtotoyIcon';
import PayPalIcon from '~/app/components/Icon/PayPalIcon';
import PatreonIcon from '~/app/components/Icon/PatreonIcon';
import IHeartRadioIcon from '~/app/components/Icon/IHeartRadioIcon';
import SongwhipIcon from '~/app/components/Icon/SongwhipIcon';
import InstagramIcon from '~/app/components/Icon/InstagramIcon';
import darkTheme from './theme/dark';
import StarIcon from '~/app/components/Icon/StarIcon';
import SnapchatIcon from '~/app/components/Icon/SnapchatIcon';
import TwitchIcon from '~/app/components/Icon/TwitchIcon';
import PlayIcon from '~/app/components/Icon/PlayIcon';
import DubsmashIcon from '~/app/components/Icon/DubsmashIcon';
import TrillerIcon from '~/app/components/Icon/TrillerIcon';
import QobuzIcon from '~/app/components/Icon/QobuzIcon';
import RakutenIcon from '~/app/components/Icon/RakutenIcon';
import RecochokuIcon from '~/app/components/Icon/RecochokuIcon';
import LineMusicIcon from '~/app/components/Icon/LineMusicIcon';
import AwaIcon from '~/app/components/Icon/AwaIcon';
import MailchimpIcon from '~/app/components/Icon/MailchimpIcon';
import DiscordIcon from '~/app/components/Icon/DiscordIcon';
import LastFmIcon from '~/app/components/Icon/LastFmIcon';
import BoomplayIcon from '~/app/components/Icon/BoomplayIcon';
import SevenDigitalIcon from '~/app/components/Icon/SevenDigitalIcon';
import AnghamiIcon from '~/app/components/Icon/AnghamiIcon';
import BandsInTownIcon from '~/app/components/Icon/BandsInTownIcon';
import JooxIcon from '~/app/components/Icon/JooxIcon';
import TelegramIcon from '~/app/components/Icon/TelegramIcon';
import RessoIcon from '~/app/components/Icon/RessoIcon';
import WynkIcon from '~/app/components/Icon/WynkIcon';
import MoraIcon from '~/app/components/Icon/MoraIcon';
import MixcloudIcon from '~/app/components/Icon/MixcloudIcon';
import BleepIcon from '~/app/components/Icon/BleepIcon';
import BeatstarsIcon from '~/app/components/Icon/BeatstarsIcon';
import LinkedInIcon from '~/app/components/Icon/LinkedInIcon';
import MoneyIconExternal from '~/app/components/Icon/MoneyIcon/external';
import LinkIcon from '~/app/components/Icon/LinkIcon';
import VkIcon from '~/app/components/Icon/VkIcon';
import { SelectedAccountConfig } from './store/lib/selectors';

export type ServiceDisplayData = {
  name: string;
  urlPattern: RegExp;
  color: string;
  colorInverted?: string;
  Icon: Icon;
  countries?: string[];
};

export const SERVICES: { [key: string]: ServiceDisplayData } = {
  [ServiceTypes.AMAZON_MUSIC]: {
    name: 'Amazon Music',
    urlPattern: /music\.amazon\./,
    color: '#166AB5',
    Icon: AmazonIcon,
  },

  [ServiceTypes.AMAZON]: {
    name: 'Amazon',
    urlPattern: /amazon\./,
    color: '#FF9900',
    Icon: AmazonIcon,
  },

  [ServiceTypes.ANGHAMI]: {
    name: 'Anghami',
    urlPattern: /anghami\./,
    color: '#f300f9',
    Icon: AnghamiIcon,
  },

  [ServiceTypes.AUDIOMACK]: {
    name: 'Audiomack',
    urlPattern: /audiomack\.com/,
    color: '#ffa201',
    Icon: AudiomackIcon,
  },

  [ServiceTypes.AUDIUS]: {
    name: 'Audius',
    urlPattern: /audius\.co/,
    color: '#bd10e0',
    Icon: AudiusIcon,
  },

  [ServiceTypes.AWA]: {
    name: 'AWA',
    urlPattern: /awa\.fm/,
    color: '#000000',
    Icon: AwaIcon,
    countries: ['JP'],
  },

  [ServiceTypes.BANDCAMP]: {
    name: 'Bandcamp',
    urlPattern: /bandcamp\.com/,
    color: '#1DA0C3',
    Icon: BandcampIcon,
  },

  [ServiceTypes.BANDS_IN_TOWN]: {
    name: 'Bandsintown',
    urlPattern: /bandsintown\./,
    color: '#00b4b3',
    Icon: BandsInTownIcon,
  },

  [ServiceTypes.BAJAO]: {
    name: 'Bajao',
    urlPattern: /bajao\.pk/,
    color: '#cf0056',
    Icon: PlayIcon,
    countries: ['PK'],
  },

  [ServiceTypes.BEATSTARS]: {
    name: 'BeatStars',
    urlPattern: /beatstars\./,
    color: '#fe2b0c',
    Icon: BeatstarsIcon,
  },

  [ServiceTypes.BEATPORT]: {
    name: 'Beatport',
    urlPattern: /beatport\./,
    color: '#93d501',
    Icon: BeatportIcon,
  },

  [ServiceTypes.BLEEP]: {
    name: 'Bleep',
    urlPattern: /bleep\./,
    color: '#373641',
    Icon: BleepIcon,
  },

  [ServiceTypes.BOOMPLAY]: {
    name: 'Boomplay',
    urlPattern: /boomplay\./,
    color: '#0052ff',
    Icon: BoomplayIcon,
  },

  [ServiceTypes.CASH_APP]: {
    name: 'Cash App',
    urlPattern: /\bcash\.app/,
    color: '#00cf31',
    Icon: MoneyIconExternal,
  },

  [ServiceTypes.DISCORD]: {
    name: 'Discord',
    urlPattern: /discord\./,
    color: '#5865f2',
    Icon: DiscordIcon,
  },

  [ServiceTypes.DUBSMASH]: {
    name: 'Dubsmash',
    urlPattern: /dubsmash\./,
    color: '#00acfe',
    Icon: DubsmashIcon,
  },

  [ServiceTypes.DEEZER]: {
    name: 'Deezer',

    // matches deezer.com and deezer.page.link
    urlPattern: /deezer\./,

    color: '#ef5466',
    Icon: DeezerIcon,
  },

  [ServiceTypes.FACEBOOK]: {
    name: 'Facebook',
    urlPattern: /facebook\.com/,
    color: '#3b5998',
    Icon: FacebookIcon,
  },

  [ServiceTypes.GAANA]: {
    name: 'Gaana',
    urlPattern: /gaana\.com/,
    color: '#e72d30',
    Icon: GaanaIcon,
    countries: ['IN', 'NP', 'PK'],
  },

  [ServiceTypes.GOOGLE]: {
    name: 'Google',
    urlPattern: /google\.com/,
    color: '#db3236',
    Icon: GoogleIcon,
  },

  [ServiceTypes.I_HEART_RADIO]: {
    name: 'iHeartRadio',
    urlPattern: /iheart\.com/,
    color: '#c6002b',
    Icon: IHeartRadioIcon,
  },

  [ServiceTypes.INSTAGRAM]: {
    name: 'Instagram',
    urlPattern: /instagram\./,
    color: '#FD1D1D',
    Icon: InstagramIcon,
  },

  [ServiceTypes.ITUNES_STORE]: {
    name: 'iTunes',
    urlPattern: /(apple\.com.+app=itunes|itunes\.apple\.com)/,
    color: '#F9425D',
    Icon: AppleIcon,
  },

  [ServiceTypes.ITUNES]: {
    name: 'Apple Music',
    urlPattern: /music\.apple\.com/,
    color: '#F9425D',
    Icon: AppleIcon,
  },

  [ServiceTypes.JOOX]: {
    name: 'Joox',
    urlPattern: /joox\./,
    color: '#02cb5d',
    Icon: JooxIcon,
  },

  [ServiceTypes.JIO_SAAVN]: {
    name: 'JioSaavn',
    urlPattern: /jiosaavn\./,
    color: '#2bc5b4',
    Icon: JioSaavnIcon,
    countries: ['IN', 'NP', 'PK'],
  },

  [ServiceTypes.KKBOX]: {
    name: 'KKBOX',
    urlPattern: /kkbox\.fm/,
    color: '#09CEF6',
    Icon: KkBoxIcon,
  },

  [ServiceTypes.LAST_FM]: {
    name: 'Last.fm',
    urlPattern: /last\.fm/,
    color: '#b90000',
    Icon: LastFmIcon,
  },

  [ServiceTypes.LINE_MUSIC]: {
    name: 'Line Music',
    urlPattern: /music\.line\.me/,
    color: '#0ad269',
    Icon: LineMusicIcon,
    countries: ['JP'],
  },

  [ServiceTypes.LINKED_IN]: {
    name: 'LinkedIn',
    urlPattern: /\blinkedin\./,
    color: '#0277b5',
    Icon: LinkedInIcon,
  },

  [ServiceTypes.MAILCHIMP]: {
    name: 'Mailchimp',
    urlPattern: /mailchimp.|eepurl./,
    color: '#007c89',
    Icon: MailchimpIcon,
  },

  [ServiceTypes.MORA]: {
    name: 'Mora',
    urlPattern: /mora\.jp/,
    color: '#08112e',
    Icon: MoraIcon,
  },

  // REVIEW: maybe not consumer facing
  [ServiceTypes.MIXCLOUD]: {
    name: 'Mixcloud',
    urlPattern: /mixcloud\./,
    color: '#586573',
    Icon: MixcloudIcon,
  },

  [ServiceTypes.NAPSTER]: {
    name: 'Napster',
    urlPattern: /napster\.com/,
    color: '#5db8f6',
    Icon: NapsterIcon,
  },

  [ServiceTypes.OTOTOY]: {
    name: 'OTOTOY',
    urlPattern: /ototoy\.jp/,
    color: '#2AB002',
    Icon: OtotoyIcon,
    countries: ['JP'],
  },

  [ServiceTypes.PANDORA]: {
    name: 'Pandora',
    urlPattern: /pandora\./,
    color: '#2296f3',
    Icon: PandoraIcon,
    countries: ['US'],
  },

  [ServiceTypes.PATREON]: {
    name: 'Patreon',
    urlPattern: /patreon.com/,
    color: '#ff424d',
    Icon: PatreonIcon,
  },

  [ServiceTypes.PAYPAL]: {
    name: 'PayPal',
    urlPattern: /paypal\./,
    color: '#253B80',
    Icon: PayPalIcon,
  },

  [ServiceTypes.QOBUZ]: {
    name: 'Qobuz',
    urlPattern: /qobuz\./,
    color: '#0070EF',
    Icon: QobuzIcon,
  },

  [ServiceTypes.RAKUTEN]: {
    name: 'Rakuten',
    urlPattern: /rakuten\./,
    color: '#bf0000',
    Icon: RakutenIcon,
  },

  [ServiceTypes.RECOCHOKU]: {
    name: 'RecoChoku',
    urlPattern: /recochoku\.jp/,
    color: '#F40071',
    Icon: RecochokuIcon,
  },

  [ServiceTypes.RESSO]: {
    name: 'Resso',
    urlPattern: /resso\./,
    color: '#f3005b',
    Icon: RessoIcon,
  },

  [ServiceTypes.REVERB_NATION]: {
    name: 'ReverbNation',
    urlPattern: /reverbnation\./,
    color: '#e43526',
    Icon: StarIcon,
  },

  [ServiceTypes.SEVEN_DIGITAL]: {
    name: '7digital',
    urlPattern: /7digital\.com/,
    Icon: SevenDigitalIcon,
    color: '#3254F0',
  },

  [ServiceTypes.SNAPCHAT]: {
    name: 'Snapchat',
    urlPattern: /snapchat\.com/,
    Icon: SnapchatIcon,

    // TODO: this color is barely visible on white
    color: '#ffff00',
  },

  [ServiceTypes.SOUND_CLOUD]: {
    name: 'SoundCloud',

    // matches soundcloud.com and soundcloud.app.goo.gl
    urlPattern: /soundcloud\./,

    color: '#FC3D0A',
    Icon: SoundCloudIcon,
  },

  [ServiceTypes.SPOTIFY]: {
    name: 'Spotify',
    urlPattern: /spotify\.com/,
    color: '#1DB954',
    Icon: SpotifyIcon,
  },

  [ServiceTypes.SONGWHIP]: {
    name: 'Songwhip',
    urlPattern: /songwhip\.com|sng\.to|orch\.stream/,
    color: darkTheme.accentColor,
    colorInverted: '#fff',
    Icon: SongwhipIcon,
  },

  [ServiceTypes.TELEGRAM]: {
    name: 'Telegram',
    urlPattern: /telegram\.|t\.me/,
    color: '#0088cc',
    Icon: TelegramIcon,
  },

  [ServiceTypes.TIDAL]: {
    name: 'Tidal',
    urlPattern: /tidal\.com/,
    color: '#3b3573',
    colorInverted: '#fff',
    Icon: TidalIcon,
  },

  [ServiceTypes.TIKTOK]: {
    name: 'TikTok',
    urlPattern: /tiktok\.com/,
    color: '#ff004f',
    Icon: TikTokIcon,
  },

  [ServiceTypes.TRILLER]: {
    name: 'Triller',
    urlPattern: /triller\./,
    color: '#ff3960',
    Icon: TrillerIcon,
  },

  [ServiceTypes.TWITCH]: {
    name: 'Twitch',
    urlPattern: /twitch\./,
    color: '#6441a4',
    Icon: TwitchIcon,
  },

  [ServiceTypes.TWITTER]: {
    name: 'X',
    urlPattern: /twitter\.com|\/\/x\.com|\/\/t\.co/,
    color: '#000',
    colorInverted: '#fff',
    Icon: TwitterIcon,
  },

  [ServiceTypes.TRAXSOURCE]: {
    name: 'Traxsource',
    urlPattern: /traxsource\./,
    color: '#3fa0ff',
    Icon: TraxsourceIcon,
  },

  [ServiceTypes.YOU_TUBE_MUSIC]: {
    name: 'YouTube Music',
    urlPattern: /music\.youtube\.com/,
    color: '#FF0000',
    Icon: YouTubeMusicIcon,
  },

  [ServiceTypes.YOU_TUBE]: {
    name: 'YouTube',

    // match youtube.com and youtu.be but not music.youtube.com
    urlPattern: /^(?!.*\.?music\.)(.*\.?)(youtube\.|youtu\.be)/,

    color: '#FF0000',
    Icon: YouTubeIcon,
  },

  [ServiceTypes.VK]: {
    name: 'VK',

    // match vk.com, vk.ru, foo.vk.com, https://vk.com
    urlPattern: /\bvk\./,

    color: '#0576fe',
    Icon: VkIcon,
  },

  [ServiceTypes.WYNK]: {
    name: 'Wynk',
    urlPattern: /wynk\./,
    color: '#fa3335',
    Icon: WynkIcon,
  },

  [ServiceTypes.YANDEX]: {
    name: 'Yandex',
    urlPattern: /yandex\./,
    color: '#ff3333',
    Icon: YandexIcon,
    countries: [
      'RU',
      'AM',
      'AZ',
      'BY',
      'GE',
      'KZ',
      'KG',
      'MD',
      'TJ',
      'TM',
      'UZ',
    ],
  },
};

const getServiceDisplayData = (
  serviceKey: string | undefined
): (ServiceDisplayData & { key: ServiceTypes }) | undefined => {
  if (!serviceKey) return undefined;

  const service = SERVICES[serviceKey];
  if (!service) return undefined;

  return { ...service, key: serviceKey as ServiceTypes };
};

export interface ResolvedServiceData
  extends Omit<ServiceDisplayData, 'color' | 'urlPattern'> {
  key: ServiceTypes | string;
  type?: ServiceTypes;
  url?: string;
  color?: string;
  match: boolean;
}

export const resolveServiceDataFromUrl = (
  url: string,
  customBrands: SelectedAccountConfig['brands'] = []
): ResolvedServiceData => {
  for (let i = 0; i < customBrands.length; i++) {
    const { urlPattern, name, Icon } = customBrands[i];

    if (new RegExp(urlPattern).test(url)) {
      return {
        key: urlPattern,
        match: true,
        name,
        Icon,
      };
    }
  }

  for (const key in SERVICES) {
    const service = SERVICES[key];
    const isMatch = service.urlPattern.test(url);

    if (isMatch) {
      return {
        key: key as ServiceTypes,
        type: key as ServiceTypes,
        match: true,
        ...service,
      };
    }
  }

  // if the url is not known then we attempt to parse a name
  // from the hostname (eg. facebook.com => 'facebook')
  const name = resolveNameFromUrl(url) || 'Unknown';

  return {
    name,
    Icon: LinkIcon,
    key: name.toLowerCase(),
    color: darkTheme.accentColor,
    match: false,
  };
};

// WARN: this don't handle hostnames w/ subdomains well
// (eg. open.spotify.com => 'Open')
const resolveNameFromUrl = (url: string) => {
  try {
    const { host } = new URL(url);

    // take the longest part of the hostname
    const result = host
      .split('.')
      .sort((a, b) => (a.length > b.length ? -1 : 1))[0];

    return result[0].toUpperCase() + result.slice(1);
  } catch (e) {}
};

export default getServiceDisplayData;
